/* eslint-disable no-control-regex */

export const telephoneRegex =
  /^(\([2-9]{1}[0-9]{2}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i;

export const isValidEmail = str => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(str);
};

export const isValidTelephoneNumber = str => {
  return telephoneRegex.test(str);
};

export const isValidName = str => {
  const isValid = /^[a-z ,.'-]+$/i.test(str);
  return isValid;
};

export const isValidBullhornId = str => {
  const isValid = /^\d+$/.test(str);
  return isValid;
};

export const zipCodeRegex = /^\d{5}(-\d{4})?$/;

export const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;

export const asciiRegex = /^[\x00-\x7F]+$/;
